import classNames from "classnames";
import PerkMegaModal from "components/Perks/PerkMegaModal/PerkMegaModal";
import React, { useEffect, useState } from "react";
import Button from "../Button/Button";
import styles from "./PerkCard.module.scss";
import Clock from "public/icons/clock.svg";
import { useRouter } from "next/router";
import { PerkType } from "@tiicker/util/lib/contentful/types";
import LimitedPerk from "./LimitedPerk/LimitedPerk";
import { useUser } from "components/UserContext/UserContext";

export type PerkCardProps = {
  id: string;
  title: string;
  imageUrl: string;
  brandLogo: string;
  tickerSymbol: string;
  noAnimate?: boolean;
  className?: string;
  cardClassName?: string;
  availableToClaim?: boolean;
  alreadyClaimed?: boolean;
  sharesUntil?: number;
  qualiType?: string;
  qualiText?: string;
  holdingRequirement?: string;
  imageClassName?: string;
  textClassName?: string;
  qualificationGroup?: number;
  notQualifiedText?: string;
  /*notQualifiedbubbleTextHoldingPeriodNotMet?: string;*/ //TODO: Fix for TiiCKER-196
  recurringDays?: number;
  key?: string;
  onClick?(perkId: string): void;
  showButton?: boolean;
  buttonType?: "primary" | "secondary" | "inline";
  buttonClass?: string;
  detailClassName?: string;
  clickToPerkUrl?: boolean;
  qualifyingBrands?: string[];
  parentPerkId?: string;
  limitedQuantityNumber?: number | null;
  contentStaticHeight?: string;
  perkType?: PerkType;
};
type Props = PerkCardProps;

const PerkCard = (props: Props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [perkIdForModal, setPerkIdForModal] = useState<string>(props.id);
  const [perkId, setPerkId] = useState<string>(props.id);
  const userContext = useUser();
  const router = useRouter();

  const userAccountsConnected =
    userContext.state.logins.filter((x) => x.inactive === false).length > 0;

  useEffect(() => {
    if (props.parentPerkId) {
      setPerkIdForModal(props.parentPerkId);
    }
  }, [props.parentPerkId]);

  // We had an edge case with switching between brand pages where the correct perk id was not being set
  useEffect(() => {
    if (props.id !== perkId) {
      setPerkId(props.id);
      if (!props.parentPerkId) {
        setPerkIdForModal(props.id);
      }
    }
  }, [props.id]);

  return (
    <>
      {modalIsOpen && props.perkType !== "Coming Soon" && (
        <PerkMegaModal
          perkId={perkIdForModal}
          isOpen={modalIsOpen}
          onClose={() => setModalIsOpen(false)}
          ticker={props.tickerSymbol}
        />
      )}
      <div
        // onClick={() => props.onClick && props.onClick(props.id)}
        onClick={() => {
          if (props.clickToPerkUrl) {
            router.push(`/perks/${props.id}`);
          } else {
            setModalIsOpen(true);
          }
        }}
        className={classNames(
          styles.animateInContainer,
          {
            [styles.animateInContainer_noAnimate]: props.noAnimate,
          },
          props.className
        )}
        data-cy="PerkCard"
      >
        <div
          className={classNames(styles.card, props.cardClassName, {
            [styles.card__noAnimate]: props.noAnimate,
          })}
        >
          <div
            className={classNames(
              styles.mainImageContainer,
              props.imageClassName
            )}
          >
            <img
              draggable={false}
              className={styles.perkImage}
              src={props.imageUrl}
            />
            {props.availableToClaim && (
              <div className={styles.availableToClaimContainer}>
                Available to Claim
              </div>
            )}
            {props.alreadyClaimed &&
              !props.availableToClaim &&
              !props.recurringDays && (
                <div className={styles.alreadyClaimedContainer}>
                  Already Claimed
                </div>
              )}
            {props.recurringDays && !props.availableToClaim && (
              <div className={styles.alreadyClaimedContainer}>
                <Clock className={styles.clock} /> Claim again in{" "}
                {props.recurringDays} Days
              </div>
            )}
            {props.notQualifiedText &&
              !props.alreadyClaimed &&
              !props.availableToClaim &&
              !props.recurringDays && (
                <div className={styles.alreadyClaimedContainer}>
                  {props.notQualifiedText}
                </div>
              )}
            {/*{props.notQualifiedbubbleTextHoldingPeriodNotMet && //TODO: Fix for TiiCKER-196
              !props.alreadyClaimed &&
              !props.availableToClaim &&
              !props.recurringDays && (
                <div className={styles.alreadyClaimedContainer}>
                  {props.notQualifiedbubbleTextHoldingPeriodNotMet}
                </div>
              )}*/}
          </div>
          <div className={classNames(styles.cardText, props.textClassName)}>
            <div>
              <div
                className={classNames(
                  styles.logoContainer,
                  props.contentStaticHeight
                )}
              >
                <img
                  draggable={false}
                  className={styles.brandLogo}
                  src={props.brandLogo}
                />
                {props.limitedQuantityNumber &&
                  props.limitedQuantityNumber > 0 && (
                    <LimitedPerk
                      perkId={perkIdForModal}
                      limitedNumber={props.limitedQuantityNumber}
                    />
                  )}
              </div>
              <h5
                className={classNames(
                  styles.cardTitle,
                  props.contentStaticHeight
                )}
              >
                {props.title}
              </h5>
            </div>
            {props.qualiType &&
              props.qualiText &&
              props.holdingRequirement &&
              props.perkType !== "Coming Soon" && (
                <div className={styles.detailContainer}>
                  <div key={`qualification-detail`}>
                    <div className={styles.detailTitle}>{props.qualiType}</div>
                    <div
                      className={classNames(
                        styles.detail,
                        props.detailClassName
                      )}
                    >
                      {props.qualiText}
                    </div>
                  </div>
                  <div key={`holding-detail`}>
                    <div className={styles.detailTitle}>
                      Holding Requirement
                    </div>
                    <div
                      className={classNames(
                        styles.detail,
                        props.detailClassName
                      )}
                    >
                      {props.holdingRequirement}
                    </div>
                  </div>
                </div>
              )}
            {props.qualifyingBrands && props.qualifyingBrands.length > 1 && (
              <div key="qualifying-brands" className={styles.qualifyingBrands}>
                <div className={styles.detailTitle}>Qualifying Brands</div>
                <div
                  className={classNames(styles.detail, props.detailClassName)}
                >
                  {props.qualifyingBrands
                    .slice(0, 3)
                    .map((brand) => brand)
                    .join(", ")}
                  {props.qualifyingBrands.length > 3 &&
                    ` and ${props.qualifyingBrands.length - 3} more`}
                </div>
              </div>
            )}

            {(props.showButton === undefined || props.showButton === true) &&
              (!props.qualiText ||
                !props.qualiType ||
                !props.holdingRequirement) && (
                <Button
                  type={props.buttonType ?? "inline"}
                  arrow
                  className={classNames(styles.button, props.buttonClass)}
                >
                  View Details
                </Button>
              )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PerkCard;
