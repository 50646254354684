import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/sharp-light-svg-icons";
import styles from "./BrokerageConnectMessage.module.scss";

const BrokerageConnectMessage = () => {
  const [isExpanded, setIsExpanded] = useState(true);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={styles.BrokerageConnectMessage}>
      <div
        className={styles.BrokerageConnectMessage__header}
        onClick={toggleExpand}
      >
        <h3 className={styles.BrokerageConnectMessage__title}>
          Not sure where to find your brokerage statement?
        </h3>
        <button
          className={styles.BrokerageConnectMessage__toggle}
          aria-label={isExpanded ? "Collapse section" : "Expand section"}
          aria-expanded={isExpanded}
        >
          <FontAwesomeIcon
            icon={isExpanded ? faMinus : faPlus}
            className={styles.BrokerageConnectMessage__icon}
          />
        </button>
      </div>
      <div
        className={`${styles.BrokerageConnectMessage__content} ${
          isExpanded ? styles.BrokerageConnectMessage__content_expanded : ""
        }`}
      >
        <p className={styles.BrokerageConnectMessage__message}>
          Visit our{" "}
          <a
            target="_blank"
            href="/brokerage-statements"
            rel="noopener noreferrer"
          >
            Brokerage Upload Instructions
          </a>{" "}
          page to view a detailed notes on where to find a brokerage statement
          for several top brokerages.
        </p>
      </div>
    </div>
  );
};

export default BrokerageConnectMessage;
