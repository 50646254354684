import styles from "./PerkClaimingOptions.module.scss";
import CircleInfo from "public/icons/circle-info.svg";
import { useFlags } from "source/hooks/useFlags";
import React from "react";

// type Props = {
//   requirementText: string;
//   holdingRequirement: string;
//   brandTicker: string;
//   brandName: string;
// };

const PerkClaimingOptions = () => {
  const flags = useFlags();
  let { aiBrokerageConnectGateway } = flags;

  return (
    <div className={styles.PerkMegaModal__container}>
      {aiBrokerageConnectGateway && (
        <>
          <div className={styles.PerkMegaModal__container__headingContainer}>
            <CircleInfo
              className={
                styles.PerkMegaModal__container__headingContainer__infoCircleImg
              }
            />
            <h4
              className={
                styles.PerkMegaModal__container__headingContainer__heading
              }
            >
              Your Options for Claiming Perks
            </h4>
          </div>
          <p className={styles.PerkMegaModal__container__requirement}>
            Connecting your brokerage to TiiCKER assures you may qualify for the
            widest offering of perks. Connect your brokerage now or you may
            upload a PDF of your latest brokerage statement for validation.
          </p>

          <p className={styles.PerkMegaModal__container__disclaimer}>
            Please note: Validation may be required again in 30 days.
          </p>
        </>
      )}
    </div>
  );
};

export default PerkClaimingOptions;
