import styles from "./FraudErrorState.module.scss";
import WarningRed from "public/icons/warning-red.svg";

// type Props = {
//   requirementText: string;
//   holdingRequirement: string;
//   brandTicker: string;
//   brandName: string;
// };

const FraudErrorState = () => {
  return (
    <div className={styles.FraudErrorState__container}>
      <div className={styles.FraudErrorState__container__headingContainer}>
        <div
          className={
            styles.FraudErrorState__container__headingContainer__infoCircleContainer
          }
        >
          <WarningRed
            className={
              styles.FraudErrorState__container__headingContainer__infoCircleContainer__warningCircleImg
            }
          />
        </div>
        <h4
          className={
            styles.FraudErrorState__container__headingContainer__heading
          }
        >
          Brokerage statement upload issue
        </h4>
      </div>

      <p className={styles.FraudErrorState__container__requirement}>
        It seems there was a problem with your upload. Please try again. If the
        problem persists, please contact{" "}
        <a href="mailto:service@tiicker.com">service@tiicker.com</a>.
      </p>
      <p className={styles.FraudErrorState__container__disclaimer}>
        {/* Please note: Validation may be required again in 30 days. */}
      </p>
    </div>
  );
};

export default FraudErrorState;
