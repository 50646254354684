import { GetNumberOfClaimsForPerk } from "$gql/queries/general/GetNumberOfClaimsForPerk.gen";
import { GetUserHoldingsForSymbols } from "$gql/queries/general/GetUserHoldingsForSymbols.gen";
import { getImageReference } from "@tiicker/util/lib/contentful/helpers";
import { PerkForId } from "@tiicker/util/lib/contentful/queries";
import {
  ContentfulContentType,
  PerkDocument,
} from "@tiicker/util/lib/contentful/types";
import Container from "components/core/Layout/Container/Container";
import Modal from "components/Modal/Modal";
import React, { useEffect, useState } from "react";
import { triggerNewPerksEvent } from "source/analytics-events";
import { useContentfulEntries } from "source/contentful/provider";
import { useQueryBundle } from "source/hooks";
import PerkMegaModalUI, { PerkMegaModalUIProps } from "./PerkMegaModalUI";
import ConnectBrokerageModal from "components/PlaidLink/ConnectBrokerageModal/ConnectBrokerageModal";
import ManualUploadModal from "components/ManualUpload/ManualUploadModal";
import { useUser } from "components/UserContext/UserContext";

type Props = {
  perkId: string;
  ticker: string;
  isOpen: boolean;
  onClose(): void;
};

const PerkMegaModal = (props: Props) => {
  const [showManualShareUpload, setShowManualShareUpload] =
    useState<boolean>(false);
  const [showBrokerageConnect, setShowBrokerageConnect] =
    useState<boolean>(false);
  const perkQuery = useContentfulEntries<PerkDocument>(
    ContentfulContentType.Perk,
    {
      query: PerkForId(props.perkId),
    }
  );
  const userContext = useUser();

  useEffect(() => {
    if (props.perkId && props.isOpen) {
      triggerNewPerksEvent(props.ticker);
    }
  }, [props.perkId, props.isOpen]);

  const isLoading = perkQuery.state !== "DONE";

  const perk =
    !isLoading && perkQuery.result.items.length > 0
      ? perkQuery.result.items[0]
      : undefined;

  const brandsOrQualifying =
    perk?.fields.qualifyingBrands && perk.fields.qualifyingBrands.length > 0
      ? perk.fields.qualifyingBrands.map((x) => x.fields.tickerSymbol)
      : [props.ticker];

  const userHolding = useQueryBundle(GetUserHoldingsForSymbols, {
    variables: {
      symbols: brandsOrQualifying,
    },
  });

  const numberOfClaims = useQueryBundle(GetNumberOfClaimsForPerk, {
    variables: {
      perkId: props.perkId,
    },
  });

  const userHoldingData =
    userHolding.state === "DONE"
      ? userHolding.data.getUserHoldingsForSymbols
      : undefined;

  const baseParams = {
    isOpen: props.isOpen,
    onClose: props.onClose,
  };

  const perkImage = perk
    ? getImageReference(
        perk.fields.image,
        perkQuery.state === "DONE" && perkQuery.result.includes
      )
    : undefined;

  const brandLogo = perk
    ? getImageReference(
        perk.fields.brand.fields.companyLogoDark,
        perkQuery.state === "DONE" && perkQuery.result.includes
      )
    : undefined;

  const openManualShareUpload = () => {
    setShowManualShareUpload(true);
  };

  const openBrokerageConnect = () => {
    setShowBrokerageConnect(true);
  };

  const params: PerkMegaModalUIProps =
    !perk || !perkImage || !brandLogo || !userHoldingData
      ? {
          isLoading: true,
          ...baseParams,
        }
      : {
          isLoading: false,
          perkId: props.perkId,
          perk: perk.fields,
          perkEntry: perk,
          brand: perk.fields.brand.fields,
          perkImageUrl: perkImage.fields.file.url,
          brandLogoUrl: brandLogo.fields.file.url,
          holdingData: userHoldingData,
          includes:
            perkQuery.state === "DONE" ? perkQuery.result.includes : undefined,
          subPerks: perk.fields.perkTierPerks ?? [],
          brandId: perk.fields.brand.sys.id,
          qualifyingBrands:
            perk?.fields.qualifyingBrands &&
            perk.fields.qualifyingBrands.length > 0
              ? perk.fields.qualifyingBrands.map((x) => x.fields)
              : undefined,
          claimCount:
            numberOfClaims.state === "DONE"
              ? numberOfClaims.data.getNumberOfClaimsForPerk
              : 0,
          openManualShareUpload: openManualShareUpload,
          openBrokerageConnect: openBrokerageConnect,
          ...baseParams,
        };

  if (showManualShareUpload) {
    return (
      <ManualUploadModal
        visible={showManualShareUpload}
        onClose={() => setShowManualShareUpload(false)}
      />
    );
  }

  if (showBrokerageConnect) {
    return (
      <ConnectBrokerageModal
        visible={showBrokerageConnect}
        onClose={() => setShowBrokerageConnect(false)}
        openPlaid={() => userContext.openPlaid()}
      />
    );
  }

  return <PerkMegaModalUI {...params} />;
};

export default PerkMegaModal;
