import AWS from "aws-sdk";

type SecretConfig = {
  region: string;
  secretName: string;
  accessKeyId: string;
  secretAccessKey: string;
};

type SecretResult = {
  data: Record<string, unknown> | null;
  error: Error | null;
};

export const fetchAWSSecret = async (
  config: SecretConfig
): Promise<SecretResult> => {
  try {
    // Configure AWS SDK
    AWS.config.update({
      region: config.region,
      credentials: new AWS.Credentials({
        accessKeyId: config.accessKeyId,
        secretAccessKey: config.secretAccessKey,
      }),
    });

    // Create Secrets Manager client
    const secretsManager = new AWS.SecretsManager({
      region: config.region,
    });

    // Fetch secret
    const data = await secretsManager
      .getSecretValue({
        SecretId: config.secretName,
      })
      .promise();

    if (data.SecretString) {
      return {
        data: JSON.parse(data.SecretString),
        error: null,
      };
    }

    throw new Error("No secret string found");
  } catch (error) {
    if (error instanceof Error) {
      // Enhance error handling for specific AWS errors
      if (error.name === "AccessDeniedException") {
        console.error("Access Denied. Please check IAM permissions:", error);
      }
      return {
        data: null,
        error: error,
      };
    }

    return {
      data: null,
      error: new Error("An unknown error occurred"),
    };
  }
};
