import styles from "./UploadError.module.scss";
import WarningRed from "public/icons/warning-red.svg";

// type Props = {
//   requirementText: string;
//   holdingRequirement: string;
//   brandTicker: string;
//   brandName: string;
// };

const UploadError = () => {
  return (
    <div className={styles.UploadError__container}>
      <div className={styles.UploadError__container__headingContainer}>
        <div className={styles.UploadError__container__headingContainer__infoCircleContainer}>
          <WarningRed className={styles.UploadError__container__headingContainer__infoCircleContainer__warningCircleImg}/> 
        </div>      
        <h4 className={styles.UploadError__container__headingContainer__heading}>Brokerage statement upload issue</h4>
      </div>

      <p className={styles.UploadError__container__requirement}>
      It seems there was a problem with your upload. Please try again. If the problem persists, please contact <a href="mailto:service@tiicker.com">service@tiicker.com</a>.
      </p>
      <p className={styles.UploadError__container__disclaimer}>
        {/* Please note: Validation may be required again in 30 days. */}
        Please note: If you have previously uploaded the same document, it doesn’t need to be uploaded again. If you believe this is an error or mistake, please contact the <a href="mailto:service@tiicker.com"> TiiCKER support team</a>.
      </p>
    </div>
  );
};

export default UploadError;