import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTriangleExclamation,
  faXmark,
} from "@fortawesome/sharp-light-svg-icons";
import Modal from "components/Modal/Modal";
import styles from "./BrokerageConnectIssue.module.scss";

export type ErrorType = "size" | "upload" | "config" | "duplicate" | "default";

type Props = {
  errorType?: ErrorType;
  closeTitle: () => void;
  visible: boolean;
};

const BrokerageConnectIssue = (props: Props) => {
  const getErrorMessage = (type?: ErrorType) => {
    const emailLink = (
      <a href="mailto:service@tiicker.com">service@tiicker.com</a>
    );

    switch (type) {
      case "size":
        return (
          <>
            The file size of a pdf upload is limited to 5mb. Please reduce the
            file size and try again. <br /> <br /> If you believe this is an
            error or mistake or if you need assistance, please{" "}
            <a href="mailto:service@tiicker.com">
              contact the TiiCKER support team
            </a>
            .
          </>
        );
      case "upload":
        return (
          <>
            There was an issue with one or more of the documents you uploaded
            indicated by a red icon above. You can try uploading the problematic
            document again or contact {emailLink} for further assistance.
          </>
        );
      case "config":
        return (
          <>
            We're experiencing technical difficulties with our configuration.
            Please contact {emailLink} for immediate assistance.
          </>
        );
      case "duplicate":
        return (
          <>
            It seems this statement has already been uploaded or associated with
            a TiiCKER account. Please note previously uploaded statements to not
            need to be uploaded again. <br /> <br /> If you believe this error
            was a mistake, please contact the
            <a href="mailto:service@tiicker.com">TiiCKER support team</a>.
          </>
        );
      default:
        return (
          <>
            It seems there was a problem with your upload. Please try again. If
            the problem persists, please contact {emailLink} for assistance.
          </>
        );
    }
  };

  const renderContent = () => (
    <div className={styles.BrokerageConnectIssue}>
      <FontAwesomeIcon
        onClick={props.closeTitle}
        icon={faXmark}
        className={styles.BrokerageConnectIssue__close}
      />
      <div className={styles.BrokerageConnectIssue__iconBackground}>
        <FontAwesomeIcon
          icon={faTriangleExclamation}
          className={styles.BrokerageConnectIssue__icon}
        />
      </div>

      <div>
        <h3 className={styles.BrokerageConnectIssue__title}>
          Brokerage statement upload issue
        </h3>
        <p className={styles.BrokerageConnectIssue__message}>
          {getErrorMessage(props.errorType)}
        </p>
      </div>
    </div>
  );

  const renderMobileContent = () => (
    <div className={styles.BrokerageConnectIssue__mobileContent}>
      <div className={styles.BrokerageConnectIssue__mobileIconContainer}>
        <div className={styles.BrokerageConnectIssue__iconBackground}>
          <FontAwesomeIcon
            icon={faTriangleExclamation}
            className={styles.BrokerageConnectIssue__icon}
          />
        </div>
      </div>

      <h3 className={styles.BrokerageConnectIssue__mobileTitle}>
        Brokerage statement upload issue
      </h3>

      <p className={styles.BrokerageConnectIssue__mobileMessage}>
        {getErrorMessage(props.errorType)}
      </p>
    </div>
  );

  // Desktop view
  if (window.innerWidth > 768) {
    return renderContent();
  }

  // Mobile view with modal
  return (
    <Modal
      visible={props.visible}
      onClose={props.closeTitle}
      modalClassName={styles.BrokerageConnectIssue__modal}
    >
      {renderMobileContent()}
    </Modal>
  );
};

export default BrokerageConnectIssue;
