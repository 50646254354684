import React, { useState, useCallback, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faXmark,
  faCloudArrowUp,
  faArrowLeft,
} from "@fortawesome/sharp-light-svg-icons";
import { faCircle } from "@fortawesome/sharp-solid-svg-icons";
import Modal from "components/Modal/Modal";
import Button from "components/core/Button/Button";
import styles from "./ManualUploadModal.module.scss";
import BrokerageConnectMessage from "components/BrokerageConnect/BrokerageConnectMessage/BrokerageConnectMessage";
import BrokerageConnectIssue from "components/BrokerageConnect/BrokerageConnectIssue/BrokerageConnectIssue";
import { ErrorType } from "components/BrokerageConnect/BrokerageConnectIssue/BrokerageConnectIssue";
import { fetchAWSSecret } from "source/awsSecrets";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useMutationBundle } from "source/hooks";
import { SaveMobileAnalysisResult } from "$gql/mutations/general/SaveMobileAnalysisResult.gen";
import { SubmitManualUpload } from "$gql/mutations/general/SubmitManualUpload.gen";
import { UpdateManualInfoApproval } from "$gql/mutations/general/UpdateManualInfoApproval.gen";
import { useUser } from "components/UserContext/UserContext";

const MySwal = withReactContent(Swal);

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB in bytes
const API_ENDPOINT =
  "https://mr5iku6pfj.execute-api.us-east-2.amazonaws.com/prod/analysis";
const AWS_REGION = "us-east-2"; // Region for Secrets Manager
const SECRET_NAME = "tii-environment";

type Props = {
  visible: boolean;
  onClose: () => void;
};

type JobStatus = {
  jobId: string;
  fileId: string; // This will be the file name to match with
  completed: boolean;
  result?: any; // Type this appropriately based on your API response
};

type FileStatus = "pending" | "success" | "error";

type FileWithStatus = File & {
  uploadStatus: FileStatus;
  errorType?: ErrorType;
  jobId?: string;
  processingResult?: any;
};

const ProcessingProgress = () => {
  const [progress, setProgress] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);

  const steps = [
    "Uploading",
    "Processing",
    "Validating Holdings",
    "Updating Your Account",
    "Finishing",
  ];

  useEffect(() => {
    const totalDuration = 140000; // 2 minutes 20 seconds
    const intervalTime = 50; // Update every 50ms for smooth animation
    const incrementPerInterval = (100 * intervalTime) / totalDuration;

    const progressInterval = setInterval(() => {
      setProgress((prev) => {
        const newProgress = Math.min(prev + incrementPerInterval, 100);
        // Update step text every 20%
        const newStep = Math.floor(newProgress / 20);
        if (newStep !== Math.floor(prev / 20)) {
          setCurrentStep(newStep);
        }
        return newProgress;
      });
    }, intervalTime);

    return () => clearInterval(progressInterval);
  }, []);

  return (
    <div
      style={{
        width: "100%",
        maxWidth: 600,
        margin: "0 auto",
        height: "100px",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "16px",
          backgroundColor: "#f0f0f0",
          borderRadius: "4px",
          overflow: "hidden",
          position: "relative",
        }}
      >
        <div
          style={{
            width: `${progress}%`,
            height: "100%",
            background: `linear-gradient(
              45deg,
              #85bb65,
              #83a270,
              #8cce65
            )`,
            backgroundSize: "200% 200%",
            animation: "gradient 2s ease infinite",
            position: "relative",
            transition: "width 50ms linear",
            borderRadius: "4px",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: `repeating-linear-gradient(
                45deg,
                transparent,
                transparent 10px,
                rgba(255,255,255,0.1) 10px,
                rgba(255,255,255,0.1) 20px
              )`,
              animation: "move 1s linear infinite",
            }}
          />
        </div>
      </div>
      <div
        style={{
          textAlign: "center",
          marginTop: "36px",
          fontSize: "16px",
          fontWeight: 500,
          color: "#091c37",
          transition: "all 0.3s ease",
          minHeight: "20px",
          lineHeight: "1.4",
        }}
      >
        {steps[currentStep]}
      </div>
      <style>
        {`
          @keyframes gradient {
            0% { background-position: 0% 50%; }
            50% { background-position: 100% 50%; }
            100% { background-position: 0% 50%; }
          }
          @keyframes move {
            0% { background-position: 0 0; }
            100% { background-position: 28px 0; }
          }
        `}
      </style>
    </div>
  );
};

const ManualUploadModal = (props: Props) => {
  const userContext = useUser();
  const [files, setFiles] = useState<FileWithStatus[]>([]);
  const [isDragging, setIsDragging] = useState(false);
  const [showStatementIssues, setShowStatementIssues] =
    useState<boolean>(false);
  const [errorType, setErrorType] = useState<ErrorType | undefined>(undefined);
  const [apiKey, setApiKey] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [saveMobileAnalysisResult] = useMutationBundle(
    SaveMobileAnalysisResult
  );
  const [submitManualUpload] = useMutationBundle(SubmitManualUpload);
  const [updateManualInfoApproval] = useMutationBundle(
    UpdateManualInfoApproval
  );

  useEffect(() => {
    const getSecret = async () => {
      const result = await fetchAWSSecret({
        region: AWS_REGION,
        secretName: SECRET_NAME,
        accessKeyId: process.env.TII_AWS_ACCESS_KEY_ID || "",
        secretAccessKey: process.env.TII_AWS_SECRET_ACCESS_KEY || "",
      });

      if (result.error) {
        if (result.error.name === "AccessDeniedException") {
          setErrorType("config");
        } else {
          setErrorType("default");
        }
        setShowStatementIssues(true);
        return;
      }

      if (result.data && "AI_API_ENDPOINT_KEY" in result.data) {
        setApiKey(result.data.AI_API_ENDPOINT_KEY as string);
      }
    };

    getSecret();
  }, []);

  const showProcessingAlert = async (jobStatusMap: Map<string, JobStatus>) => {
    return MySwal.fire({
      title: "Processing Your Brokerage Statement",
      html: <ProcessingProgress />,
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false,
      didOpen: async () => {
        // Start polling for status updates
        await pollJobStatuses(jobStatusMap);

        // After polling is complete, check final results
        const allResults = Array.from(jobStatusMap.values());
        const hasErrors = allResults.some(
          (job) => job.result?.status === "FAILED" || !job.completed
        );

        if (hasErrors) {
          setErrorType("upload");
          setShowStatementIssues(true);
        }

        MySwal.close();
      },
    });
  };

  const validateFileSize = (file: File): boolean => {
    return file.size <= MAX_FILE_SIZE;
  };

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDrop = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    setShowStatementIssues(false);

    const droppedFiles = Array.from(e.dataTransfer.files).filter(
      (file): file is File => file.type === "application/pdf"
    );

    const hasOversizedFiles = droppedFiles.some(
      (file) => !validateFileSize(file)
    );

    if (hasOversizedFiles) {
      setErrorType("size");
      setShowStatementIssues(true);
      return;
    }

    const filesWithStatus = droppedFiles.map((file) =>
      Object.assign(file, { uploadStatus: "pending" as FileStatus })
    );

    setFiles((prev) => [...prev, ...filesWithStatus]);
  }, []);

  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowStatementIssues(false);

    if (e.target.files) {
      const selectedFiles = Array.from(e.target.files).filter(
        (file): file is File => file.type === "application/pdf"
      );

      const hasOversizedFiles = selectedFiles.some(
        (file) => !validateFileSize(file)
      );

      if (hasOversizedFiles) {
        setErrorType("size");
        setShowStatementIssues(true);
        return;
      }

      const filesWithStatus = selectedFiles.map((file) =>
        Object.assign(file, { uploadStatus: "pending" as FileStatus })
      );

      setFiles((prev) => [...prev, ...filesWithStatus]);
    }
  };

  const removeFile = (indexToRemove: number) => {
    setFiles((prev) => prev.filter((_, index) => index !== indexToRemove));
  };

  const checkJobStatus = async (jobId: string): Promise<any> => {
    try {
      console.log(`Checking status for job: ${jobId}`);
      const response = await fetch(`${API_ENDPOINT}/${jobId}`, {
        headers: {
          "x-api-key": apiKey,
        },
      });

      if (!response.ok) {
        throw new Error(`Status check failed: ${await response.text()}`);
      }

      const result = await response.json();
      console.log(`Status check result for job ${jobId}:`, result);
      return result;
    } catch (error) {
      console.error(`Status check error for job ${jobId}:`, error);
      throw error;
    }
  };

  const pollJobStatuses = async (
    jobStatusMap: Map<string, JobStatus>
  ): Promise<void> => {
    const pollInterval = 10000; // Poll every 10 seconds
    const maxTime = 140000; // 2 minutes 20 seconds (matching your timer)
    const startTime = Date.now();
    console.log("Starting job status polling with map:", jobStatusMap);

    while (Date.now() - startTime < maxTime) {
      const pendingJobs = Array.from(jobStatusMap.values()).filter(
        (job) => !job.completed
      );

      console.log(`Current pending jobs: ${pendingJobs.length}`, pendingJobs);

      if (pendingJobs.length === 0) {
        console.log("All jobs completed");
        // Check if all jobs were successful
        const allSuccessful = Array.from(jobStatusMap.values()).every(
          (job) => job.result?.status === "COMPLETE"
        );
        if (allSuccessful) {
          props.onClose();
        }
        break;
      }

      // Check all pending jobs
      await Promise.all(
        pendingJobs.map(async (job) => {
          try {
            console.log(`Checking job ${job.jobId} for file ${job.fileId}`);
            const result = await checkJobStatus(job.jobId);
            console.log(`Received status for job ${job.jobId}:`, result);

            // Check if the job is no longer in progress
            if (result.status !== "IN PROGRESS") {
              console.log(
                `Job ${job.jobId} finished with status: ${result.status}`
              );

              // Update the job status map
              jobStatusMap.set(job.jobId, {
                ...job,
                completed: true,
                result: result,
              });

              if (result.status === "COMPLETE") {
                try {
                  // First save the analysis result
                  await saveMobileAnalysisResult({
                    variables: {
                      results: JSON.stringify(result),
                      jobId: job.jobId,
                    },
                  });

                  // Then submit the manual upload
                  const submitResponse = await submitManualUpload({
                    variables: {
                      userId: userContext.state.userId || 0,
                      jobId: job.jobId,
                      s3ImageBucket: result.s3ImageBucket,
                      s3DocumentBucket: result.s3DocumentBucket,
                      s3ImageKey: result.s3ImageKey,
                      s3DocumentKey: result.s3DocumentKey,
                      status: result.status,
                      creationDate: result.timestamp,
                    },
                  });

                  // Finally update approval status
                  if (submitResponse) {
                    await updateManualInfoApproval({
                      variables: {
                        jobId: job.jobId,
                        needsApproval: false,
                        approved: true,
                      },
                    });
                  }
                } catch (error) {
                  console.error("Error processing successful upload:", error);
                }
              } else if (result.status === "FAILED") {
                try {
                  // Submit the manual upload for failed case
                  const submitResponse = await submitManualUpload({
                    variables: {
                      userId: userContext.state.userId || 0,
                      jobId: job.jobId,
                      s3ImageBucket: result.s3ImageBucket,
                      s3DocumentBucket: result.s3DocumentBucket,
                      s3ImageKey: result.s3ImageKey,
                      s3DocumentKey: result.s3DocumentKey,
                      status: result.status,
                      creationDate: result.timestamp,
                    },
                  });

                  // Update approval status for failed case
                  if (submitResponse) {
                    await updateManualInfoApproval({
                      variables: {
                        jobId: job.jobId,
                        needsApproval: true,
                        approved: false,
                      },
                    });
                  }
                } catch (error) {
                  console.error("Error processing failed upload:", error);
                }
              }

              // Update files state, removing successful ones and keeping only failed ones
              setFiles((prev) => {
                const updatedFiles = prev.map((file) => {
                  if (file.jobId === job.jobId) {
                    console.log(`Updating status for file: ${file.name}`, {
                      oldStatus: file.uploadStatus,
                      newStatus:
                        result.status === "COMPLETE" ? "success" : "error",
                    });

                    const newStatus: FileStatus =
                      result.status === "COMPLETE" ? "success" : "error";

                    return {
                      ...file,
                      uploadStatus: newStatus,
                      processingResult: result,
                      errorType:
                        result.status === "FAILED"
                          ? ("upload" as ErrorType)
                          : undefined,
                    } as FileWithStatus;
                  }
                  return file;
                });
                
                // Filter out successful uploads, only keeping failed ones
                const filteredFiles = updatedFiles.filter(file => file.uploadStatus !== "success");
                return filteredFiles;
              });
            } else {
              console.log(
                `Job ${job.jobId} still processing with status: ${result.status}`
              );
            }
          } catch (error) {
            console.error(`Error checking status for job ${job.jobId}:`, error);
          }
        })
      );

      console.log(`Waiting ${pollInterval}ms before next poll`);
      await new Promise((resolve) => setTimeout(resolve, pollInterval));
    }
  };

  const uploadFile = async (file: FileWithStatus): Promise<string | null> => {
    try {
      console.log(`Starting upload for file: ${file.name}`);
      const formData = new FormData();
      formData.append("file", file);

      const response = await fetch(API_ENDPOINT, {
        method: "POST",
        headers: {
          "x-api-key": apiKey,
        },
        mode: "cors",
        body: formData,
      });

      if (!response.ok) {
        throw new Error(
          `Upload failed for ${file.name}: ${await response.text()}`
        );
      }

      const responseData = await response.json();
      console.log(`Upload success response for ${file.name}:`, responseData);

      // Create a new file object instead of mutating the existing one
      setFiles((prev) =>
        prev.map((f) =>
          f.name === file.name
            ? {
                ...f,
                uploadStatus: "pending" as FileStatus,
                jobId: responseData.jobId,
                name: file.name, // Ensure name is preserved
                size: file.size, // Ensure size is preserved
              }
            : f
        )
      );

      return responseData.jobId;
    } catch (error) {
      console.error(`Upload error for ${file.name}:`, error);

      setFiles((prev) =>
        prev.map((f) =>
          f.name === file.name
            ? {
                ...f,
                uploadStatus: "error" as FileStatus,
                errorType: "upload",
                name: file.name, // Ensure name is preserved
                size: file.size, // Ensure size is preserved
              }
            : f
        )
      );

      setErrorType("upload");
      setShowStatementIssues(true);
      return null;
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    setShowStatementIssues(false);

    try {
      console.log("Starting file uploads:", files);
      const uploadPromises = files.map((file) => uploadFile(file));
      const jobIds = await Promise.all(uploadPromises);

      console.log("Upload responses:", jobIds);
      console.log("Files state after upload:", files);

      const jobStatusMap = new Map<string, JobStatus>();
      jobIds.forEach((jobId, index) => {
        if (jobId !== null) {
          console.log(
            `Creating job status mapping for ${files[index].name} with jobId: ${jobId}`
          );
          jobStatusMap.set(jobId, {
            jobId,
            fileId: files[index].name,
            completed: false,
          });
        }
      });

      console.log("Created job status map:", jobStatusMap);

      // Only proceed if we have any successful uploads
      if (jobStatusMap.size > 0) {
        await showProcessingAlert(jobStatusMap);
      }
    } catch (error) {
      console.error("Submit error:", error);
      setErrorType("upload");
      setShowStatementIssues(true);
    } finally {
      setIsLoading(false);
    }
  };

  const formatFileSize = (bytes: number): string => {
    const units = ["B", "KB", "MB", "GB"];
    let size = bytes;
    let unitIndex = 0;

    while (size >= 1024 && unitIndex < units.length - 1) {
      size /= 1024;
      unitIndex++;
    }

    return `${Math.round(size * 10) / 10} ${units[unitIndex]}`;
  };

  const getStatusColor = (status: FileStatus) => {
    switch (status) {
      case "success":
        return "#85BB65"; // Green
      case "error":
        return "#EF4444"; // Red
      default:
        return "#6B7280"; // Grey
    }
  };

  return (
    <Modal
      visible={props.visible}
      onClose={props.onClose}
      fullWidthSmall
      modalClassName={styles.ManualUploadModal}
      blockOutsideClick={true}
    >
      <div className={styles.ManualUploadModal__content}>
        <div className={styles.ManualUploadModal__container}>
          {/* Left Column */}
          <div className={styles.ManualUploadModal__column}>
            <Button type="inline" noAnimate onClick={props.onClose}>
              <FontAwesomeIcon
                icon={faArrowLeft}
                className={styles.ManualUploadModal__arrowLeftIcon}
              />
              Back to Perk
            </Button>
            <h2 className={styles.ManualUploadModal__title}>
              Upload Brokerage Statement
            </h2>
            <h3 className={styles.ManualUploadModal__subtitle}>
              The following information must be included on all statements:
            </h3>

            <ul className={styles.ManualUploadModal__list}>
              <li className={styles.ManualUploadModal__listItem}>Full name</li>
              <li className={styles.ManualUploadModal__listItem}>
                Brokerage name
              </li>
              <li className={styles.ManualUploadModal__listItem}>
                Date of Statement
              </li>
              <li className={styles.ManualUploadModal__listItem}>
                Stocks held
              </li>
              <li className={styles.ManualUploadModal__listItem}>
                Number of shares held
              </li>
              <li className={styles.ManualUploadModal__listItem}>
                Value of portfolio
              </li>
            </ul>

            <p className={styles.ManualUploadModal__text}>
              Please note: Validation may be required again in 30 days if the
              perk has a holding period requirement
            </p>

            <BrokerageConnectMessage />
          </div>

          {/* Right Column */}
          <div className={styles.ManualUploadModal__column}>
            <div
              className={`${styles.ManualUploadModal__dropzone} ${
                isDragging ? styles["ManualUploadModal__dropzone--active"] : ""
              }`}
              onDragEnter={handleDragEnter}
              onDragOver={(e) => e.preventDefault()}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
            >
              <FontAwesomeIcon
                icon={faCloudArrowUp}
                className={styles.ManualUploadModal__dropzone__icon}
              />
              <div className={styles.ManualUploadModal__dropzone__textBlock}>
                <p className={styles.ManualUploadModal__dropzone__text}>
                  Drag & Drop
                </p>
                <p className={styles.ManualUploadModal__dropzone__subtext}>
                  or
                </p>
                <label className={styles.ManualUploadModal__fileLabel}>
                  Browse Files
                  <input
                    type="file"
                    accept=".pdf"
                    multiple
                    className={styles.ManualUploadModal__fileInput}
                    onChange={handleFileInput}
                  />
                </label>
              </div>
              <p className={styles.ManualUploadModal__dropzone__sizeText}>
                [.pdf files only, up to 5MB, multiple files accepted]
              </p>
            </div>

            <div className={styles.ManualUploadModal__fileList}>
              {files.map((file, index) => (
                <div key={index} className={styles.ManualUploadModal__fileItem}>
                  <div className={styles.ManualUploadModal__fileInfo}>
                    <FontAwesomeIcon
                      // @ts-ignore
                      icon={faCircle}
                      className={styles.ManualUploadModal__fileIcon}
                      style={{ color: getStatusColor(file.uploadStatus) }}
                    />
                    <span className={styles.ManualUploadModal__fileName}>
                      {file.name}
                    </span>
                  </div>
                  <div className={styles.ManualUploadModal__fileActions}>
                    <span className={styles.ManualUploadModal__fileSize}>
                      {formatFileSize(file.size)}
                    </span>
                    <button
                      className={styles.ManualUploadModal__fileRemove}
                      onClick={() => removeFile(index)}
                    >
                      <FontAwesomeIcon icon={faXmark} />
                    </button>
                  </div>
                </div>
              ))}
            </div>

            {showStatementIssues && (
              <BrokerageConnectIssue
                errorType={errorType}
                closeTitle={() => setShowStatementIssues(false)}
                visible={showStatementIssues}
              />
            )}

            <Button
              onClick={handleSubmit}
              type="primary"
              disabled={files.length === 0 || isLoading}
            >
              {isLoading ? "Uploading..." : "Submit"}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ManualUploadModal;
