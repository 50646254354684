import styles from "./DuplicateUploadError.module.scss";
import WarningRed from "public/icons/warning-red.svg";

// type Props = {
//   requirementText: string;
//   holdingRequirement: string;
//   brandTicker: string;
//   brandName: string;
// };

const DuplicateUploadError = () => {
  return (
    <div className={styles.DuplicateUploadError__container}>
      <div className={styles.DuplicateUploadError__container__headingContainer}>
        <div className={styles.DuplicateUploadError__container__headingContainer__infoCircleContainer}>
          <WarningRed className={styles.DuplicateUploadError__container__headingContainer__infoCircleContainer__warningCircleImg}/> 
        </div>      
        <h4 className={styles.DuplicateUploadError__container__headingContainer__heading}>Brokerage statement upload issue</h4>
      </div>

      <p className={styles.DuplicateUploadError__container__requirement}>
        It seems this statement has already been uploaded or associated with a TiiCKER account. Please note that previously uploaded statements do not need to be uploaded again. 
      </p>
      <p className={styles.DuplicateUploadError__container__disclaimer}>
        {/* Please note: Validation may be required again in 30 days. */}
        If you believe this error was a mistake, please contact the <a href="mailto:service@tiicker.com"> TiiCKER support team</a>.
      </p>
    </div>
  );
};

export default DuplicateUploadError;